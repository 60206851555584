.Toolbar {
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 52px;
  padding: 0 6px;
  overflow: hidden;
  color: #ffffff;
  background: #007aff;
  box-shadow: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.25);
}

.Toolbar button, .Toolbar a {
  padding: 12px;
  color: #ffffff;
  font-size: 28px;
  line-height: 20px;
  text-decoration: none;
}

.UndoRedo {
  white-space: nowrap;
}

.UndoRedo button:disabled {
  opacity: 0;
}

.UndoRedo button:first-child {
  margin-right: 16px;
}

.open span {
  color: #000000;
}
