.Monitor {
  position: fixed;
  right: 0;
  width: 30vw;
  height: 100%;
  color: #ffffff;
  background: #262626;
}

.Monitor__Wrapper {
  position: relative;
  height: 100%;
  margin: 52px 0 64px 0;
}

.MonitorToolbar {
  z-index: 2;
  padding: 8px 0 4px 5px;
  background: #262626;
}

.MonitorToolbar h3 {
  display: inline-block;
  margin: 0;
  color: #ffffff;
  font-weight: normal;
  line-height: 24px;
}

.MonitorToolbar button {
  float: right;
  width: 120px;
  padding: 1px 8px;
  margin-right: 5px;
  font-size: 14px;
  line-height: 22px;
  vertical-align: bottom;
  background: transparent;
  background: #007aff;
  border: none;
  border-radius: 2px;
}

.MonitorListWrapper {
  position: absolute;
  width: 100%;
  height: calc(100% - 152px);
  overflow-y: scroll;

  -webkit-overflow-scrolling: touch;
}

.MonitorListEmpty {
  padding: 14px 8px 4px 6px;
  color: #cccccc;
  font-style: oblique;
}

.Monitor ul {
  height: 100%;
  padding: 0;
  margin: 0;
}

.MonitorItem {
  position: relative;
  padding: 12px 12px 12px 24px;
  overflow: hidden;
  list-style: none;
  background: #404040;
  border: solid #262626 4px;
  border-bottom: 0;
}

.MonitorItem--inactive .MonitorItem__body {
  opacity: 0.3;
}

.MonitorItem__origin {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 100%;
  background: transparent;
  transform: none;
}

.MonitorItem:last-child {
  border-bottom: solid #262626 4px;
}

.MonitorItem__info {
  font-weight: bold;
}

.MonitorItem button {
  position: absolute;
  top: 12px;
  right: 12px;
}

.MonitorItem pre {
  font-size: 11px;
}

.SyncStatus--synced {
  color: #00ff00;
}

.SyncStatus--not_synced {
  color: #ff0000;
}

@media screen and (max-width: 480px) {
  .Monitor {
    width: 100%;
  }
}
