@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?tiexs9');
  src:  url('fonts/icomoon.eot?tiexs9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?tiexs9') format('truetype'),
    url('fonts/icomoon.woff?tiexs9') format('woff'),
    url('fonts/icomoon.svg?tiexs9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloud-check:before {
  content: "\e901";
}
.icon-cloud-cross:before {
  content: "\e900";
}
.icon-clock:before {
  content: "\e94e";
}
.icon-undo:before {
  content: "\e965";
}
.icon-redo:before {
  content: "\e966";
}
.icon-cloud:before {
  content: "\e9c1";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-share2:before {
  content: "\ea82";
}
