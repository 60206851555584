html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  font-family: sans-serif;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  color: #ffffff;
  background: transparent;
  border: none;
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a:active, button:active span {
  text-shadow: 0 0 24px white;
}

button:focus {
  outline: 0;
}

button:disabled {
  color: #888b8f;
}

button:active:disabled span {
  text-shadow: none;
}

input[type=text] {
  border: solid #999999 1px;
}
