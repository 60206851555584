.ConnectionStatus {
  font-size: 46px;
  line-height: 49px;
}

.ConnectionStatus .icon {
  font-size: 38px;
  line-height: 28px;
}

.ConnectionStatus .CONNECTED:before {
  content: '\e901';
}

.ConnectionStatus .DISCONNECTED:before {
  color: #888b8f;
  content: '\e900';
}

.ConnectionStatus .CONNECTING {
  color: #888b8f;
  animation: blink 1s step-start 0s infinite;
}

@-webkit-keyframes blink {
  50% {
    color: #ffffff;
  }
}
@keyframes blink {
  50% {
    color: #ffffff;
  }
}
