.Todos__List {
  position: absolute;
  top: 50px;
  bottom: 64px;
  left: 0;
  width: 100%;
  height: calc(100% -124px);
  padding: 0;
  overflow-y: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-overflow-scrolling: touch;
}

.Todos-List--with-monitor {
  width: 70vw;
}

.Todos ul {
  padding: 0;
  margin: 0;
}

.Todos li {
  position: relative;
  min-height: 30px;
  padding: 18px 18px 14px 18px;
  list-style-type: none;
  background: #ffffff;
  border-bottom: solid #cccccc 1px;
}

.Todos li:first-child {
  border-top: solid #cccccc 1px;
}

.Todos__List li span {
  position: absolute;
  top: 20px;
  font-size: 28px;
  line-height: 28px;
  cursor: default;
}

.Todos li div {
  display: block;
  width: auto;
  max-width: 660px;
  margin: 0 48px;
  overflow: hidden;
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  text-overflow: ellipsis;
  cursor: default;
}

.Todos li button {
  position: absolute;
  top: 6px;
  right: 4px;
  box-sizing: border-box;
  padding: 0 12px;
  color: #ff0000;
  font-size: 46px;
  line-height: 46px;
}

.Todos li button::after {
  content: '\00D7';
}

.Todos__InputArea {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 64px;
  min-height: 64px;
  background: #007aff;
  box-shadow: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.25);
}

.Todos__InputArea input {
  width: calc(100% - 110px);
  max-width: 660px;
  padding: 6px;
  margin-top: 14px;
  margin-left: 14px;
  color: #333333;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 18px;
  border-radius: 4px;
}

.Todos__InputArea button {
  width: 50px;
  padding: 0;
  margin-left: 12px;
  font-size: 20px;
  line-height: 34px;
  vertical-align: bottom;
  box-shadow: none;
  border: 0;
}
